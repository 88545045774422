import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import { CheckCircle } from "@mui/icons-material";
import Header from "../../Components/header/Header";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Autocomplete from '@mui/material/Autocomplete';
import Container from "@mui/material/Container";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
    stepConnectorClasses,
} from "@mui/material/StepConnector";
import api from "../../service/api";
import image from '../../images/logo1.png';
import cachet from '../../images/cachet_senpharma.png';
import Dialog from '@mui/material/Dialog';
import "./style.css";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';


const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 8,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: "#FCA13A",
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: "#FCA13A",
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 2,
        border: 0,
        backgroundColor:
            theme.palette.mode === "dark" ? theme.palette.grey[800] : "#E6EDFE",
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.grey[700] : "none",
    zIndex: 1,
    width: 15,
    border: "1px solid #E6EDFE",
    height: 15,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
        border: "1px solid #FCA13A",
    }),
    ...(ownerState.completed && {
        border: "1px solid #FCA13A",
        backgroundColor: "#FCA13A",
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: "",
        2: "",
        3: "",
        4: "",
    };

    return (
        <ColorlibStepIconRoot
            ownerState={{ completed, active }}
            className={className}
        >
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

const steps = ["Choix", "Formulaire", "Facture", "Merci"];

const Devis = () => {
    const [openNew, setOpenNew] = useState(false)


    const handleOuvert = () => {
        setOpenNew(true)
    };
    const out = () => {
        setOpenNew(false)
    };

    const down = () => {

        const printWindow = window.open('', '', 'width=600,height=600');
        printWindow.document.open();
        printWindow.document.write(`
                    <html>
                    <head>
                        <title>.</title>
                        <style>
                                .custom-span {
    font-size: 14px;
    font-weight: 700;
    margin-left: 40px;
}

.custom-span1 {
    font-size: 14px;
    font-weight: 700;
    margin-left: 10px;
}

.printdesc {
    margin-left: 20px;
    margin-top: 30px;
    display: flex;
    grid-gap: 250px;
}

.printable {
    /* border-collapse: collapse; */
    margin-top: 40px;
    width: 95%;
}

.printdesc1 .paddress {
    font-size: 8px;

}
.Action {
    padding: 15px 30px;
    position: relative;
    left: -300px;
    top: 30px;
    background: #FCA13A;
    border: none;
    color: #fff;
}

.td {
    padding: 12px 100px;
}

.td1 {
    padding: 12px 50px;
}

.printimg {
    width: 300px;
    height: 300px;
    margin-left: 200px;
    margin-top: 50px;
}

.printbutton {
    padding: 15px 30px;
    background: #b1e0e9;
    border: none;
    color: #fff;
    margin-left: 250px;
    margin-top: 10px;
    width: 25%;
}
                        </style>

                    </head>
                    <body>
                    ${document.getElementById('print').innerHTML}
                    </body>
                    </html>
                    `);
        printWindow.document.close();
        printWindow.print();
        printWindow.close();
        setOpenNew(false)


    };

    const onclose = () => {
        const divToCapture = document.getElementById('print'); // Remplacez 'divId' par l'ID de votre div

        if (!divToCapture) {
            console.error('Div not found.');
            return;
        }
        html2canvas(divToCapture).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            pdf.addImage(imgData, 'PNG', 10, 10, 180, 0); // Vous pouvez personnaliser la taille et la position de l'image
            const pdfData = pdf.output('blob'); // Obtenir les données du PDF au format blob
            const pdfUrl = URL.createObjectURL(pdfData);

            // Créer un lien de téléchargement
            const a = document.createElement('a');
            a.href = pdfUrl;
            a.download = 'facture-proforma.pdf';
            a.
                a




            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(pdfUrl); // Libérer les ressources


        });

        setOpenNew(false);

    }



    const navigate = useNavigate();
    const { id } = useParams()
    const [data, setData] = useState();
    const [prenom, setPrenom] = useState('')
    const [nom, setNom] = useState('')
    const [nom_structure, setNom_structure] = useState('')
    const [email, setEmail] = useState('')
    const [telephone, setTelephone] = useState('')
    const [adresse, setAdresse] = useState('')
    const [region, setRegion] = useState('')
    const [ville, setVille] = useState('')
    const [pays, setPays] = useState(null)
    const [logo, setLogo] = useState(null)
    const [serviceId, setServiceId] = useState(id)
    const [montant_restant, setMontant_restant] = useState('');




    const getserviceid = async () => {
        try {
            const response = await api.getserviceid(id)
            if (response) {
                setData(response.data);

            }
        } catch (error) {
            console.log(error)
        }
    };


    useEffect(() => {
        getserviceid();
    }, []);



    const handleSubmit = async (e) => {
        e.preventDefault();
        handleOuvert()
        // const inputData = {
        //     prenom: prenom,
        //     nom: nom,
        //     nom_structure: nom_structure,
        //     pays: pays,
        //     region: region,
        //     ville: ville,
        //     telephone: telephone,
        //     email: email,
        //     adresse: adresse,
        //     montant_restant: montant_restant,
        //     serviceId: serviceId
        // }
        // console.log(inputData)

        // try {
        //     const resp = await api.postuserstand(formData);
        //     console.log(resp.data)
        //     localStorage.setItem("response", JSON.stringify(resp.data));

        //     // console.log(resp.data)
        //     window.location.href = "/partenaire/FinReservation";

        // } catch (error) {
        //     // console.log(error)
        // }



    }

    // const handleChange = (name, value) => {
    //   // console.log(` ${value}`);


    // };

    // const handleAutocompleteChange = (event, newValue) => {
    //   setPays(newValue);
    //   handleChange('pays', newValue ? newValue.label : null);
    //   console.log(pays);
    // };


    const [isSmallScreen, setIsSmallScreen] = useState(false);


    useEffect(() => {
        function handleResize() {
            // Mettre à jour isSmallScreen en fonction de la largeur de l'écran
            setIsSmallScreen(window.innerWidth <= 768);
        }

        // Écouter les changements de taille d'écran
        window.addEventListener('resize', handleResize);

        // Appel initial de la fonction de gestion de la taille de l'écran
        handleResize();

        // Nettoyage de l'écouteur d'événements lors du démontage du composant
        return () => {
            window.removeEventListener('resize', handleResize);
        }


    }, []);

    const [isFalse, setIsFalse] = useState(true);

    const handleToggle = () => {
        setIsFalse(!isFalse);
        // console.log(isFalse);
    };







    return (
        <div className="containe">
            <Header handleMenu={handleToggle} />

            <div className="main-section">

                <div className="section22">
                    <div className="main-container1">
                        <>
                            <div>
                                <h1>{data?.nom_service}</h1>
                                <p>{data?.prix.toLocaleString()}</p>
                                <table className="tablereservation">
                                    <tr>
                                        <td>{data?.dimension}</td>
                                        <td className="icon">
                                            <CheckCircle />
                                        </td>
                                    </tr>
                                    {(() => {
                                        if (data?.book === null) {
                                        } else {
                                            return (
                                                <tr>
                                                    <td>{data?.book}</td>
                                                    <td className="icon">
                                                        <CheckCircle />
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    })()}

                                    {(() => {
                                        if (data?.place === null) {
                                        } else {
                                            return (

                                                <tr>
                                                    <td>{data?.place} </td>
                                                    <td className="icon">
                                                        <CheckCircle />
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    })()}


                                    {(() => {
                                        if (data?.desk === null) {
                                        } else {
                                            return (

                                                <tr>
                                                    <td>{data?.desk} </td>
                                                    <td className="icon">
                                                        <CheckCircle />
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    })()}

                                    {(() => {
                                        if (data?.support_communication === null) {
                                        } else {
                                            return (
                                                <tr>
                                                    <td>
                                                        {data?.support_communication}
                                                    </td>
                                                    <td className="icon">
                                                        <CheckCircle />
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    })()}

                                    {(() => {
                                        if (data?.Kakemono === "" || data?.Kakemono === null) {
                                        } else {
                                            return (
                                                <tr>
                                                    <td>
                                                        {data?.Kakemono}
                                                    </td>
                                                    <td className="icon">
                                                        <CheckCircle />
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    })()}

                                    {(() => {
                                        if (data?.salle_pleniere === null) {
                                        } else {
                                            return (
                                                <tr>
                                                    <td>
                                                        {data?.salle_pleniere}
                                                    </td>
                                                    <td className="icon">
                                                        <CheckCircle />
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    })()}

                                    {(() => {
                                        if (data?.salle_commission === null) {
                                        } else {
                                            return (
                                                <tr>
                                                    <td>
                                                        {data?.salle_commission}
                                                    </td>
                                                    <td className="icon">
                                                        <CheckCircle />
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    })()}

                                    {(() => {
                                        if (data?.espace_networking === null) {
                                        } else {
                                            return (
                                                <tr>
                                                    <td>
                                                        {data?.espace_networking}
                                                    </td>
                                                    <td className="icon">
                                                        <CheckCircle />
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    })()}

                                    {(() => {
                                        if (data?.publireportage === null) {
                                        } else {
                                            return (
                                                <tr>
                                                    <td>
                                                        {data?.publireportage}
                                                    </td>
                                                    <td className="icon">
                                                        <CheckCircle />
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    })()}



                                </table>

                            </div>

                        </>
                    </div>

                    <span class="vertical-line"></span>

                    <div className="main-container2">
                        <div className="form22" style={{}}>
                            <Container
                                component="main"
                                sx={{ width: 340, padding: "30px 40px" }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                    }}
                                >
                                    <p
                                        style={{
                                            color: "#2E2E2E",
                                            fontSize: "20px",
                                            fontWeight: 600,
                                            // marginTop: "30px"
                                            // position: "relative",
                                            // bottom: "20px",
                                        }}
                                    >
                                        Demande de facture proforma
                                    </p>
                                    <form onSubmit={handleSubmit}
                                        style={{ width: "240px", height: "100px", marginTop: "30px" }}>

                                        <div className="item">
                                            {isFalse && (
                                                <>
                                                    <Grid
                                                        container
                                                        spacing={1}
                                                        sx={{ marginTop: "-30px", textTransform: "uppercase" }}
                                                    >
                                                        <Grid item xs={12} sm={6}>
                                                            <p
                                                                style={{
                                                                    textAlign: "justify",
                                                                    textTransform: "uppercase",
                                                                    color: "#000000",
                                                                    fontSize: "12px",
                                                                    opacity: 0.5,
                                                                }}
                                                            >
                                                                Prénom
                                                            </p>
                                                            <TextField
                                                                onChange={(e) => {
                                                                    setPrenom(e.target.value)
                                                                    setMontant_restant(data.prix)
                                                                }}
                                                                autoComplete="prenom"
                                                                name="prenom"
                                                                value={prenom}
                                                                required
                                                                fullWidth
                                                                id="prenom"
                                                                autoFocus
                                                                sx={{
                                                                    marginBottom: "-10px",
                                                                    background: "#F9FAFF",
                                                                    border: "0.53437 1px solid #D5DCFF",
                                                                    "& .MuiInputBase-root": {
                                                                        height: 30,
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <p
                                                                style={{
                                                                    textAlign: "justify",
                                                                    textTransform: "uppercase",
                                                                    color: "#000000",
                                                                    fontSize: "12px",
                                                                    opacity: 0.5,
                                                                }}
                                                            >
                                                                nom
                                                            </p>
                                                            <TextField
                                                                required
                                                                fullWidth
                                                                value={nom}
                                                                onChange={(e) => setNom(e.target.value)}
                                                                id="nom"
                                                                name="nom"
                                                                autoComplete="nom"
                                                                sx={{
                                                                    background: "#F9FAFF",
                                                                    marginBottom: "-10px",
                                                                    border: "0.53437 1px solid #D5DCFF",
                                                                    "& .MuiInputBase-root": {
                                                                        height: 30,
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <p
                                                                style={{
                                                                    textAlign: "justify",
                                                                    textTransform: "uppercase",
                                                                    color: "#000000",
                                                                    fontSize: "12px",
                                                                    opacity: 0.5,
                                                                }}
                                                            >
                                                                Structure
                                                            </p>
                                                            <TextField
                                                                required
                                                                fullWidth
                                                                onChange={(e) => setNom_structure(e.target.value)}
                                                                value={nom_structure}
                                                                id="nom_structure"
                                                                name="nom_structure"
                                                                autoComplete="nom"
                                                                sx={{
                                                                    background: "#F9FAFF",
                                                                    border: "0.53437 1px solid #D5DCFF",
                                                                    marginBottom: "-10px",

                                                                    "& .MuiInputBase-root": {
                                                                        height: 30,
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>


                                                        <Grid item xs={12} sm={6}>
                                                            <p
                                                                style={{
                                                                    textAlign: "justify",
                                                                    textTransform: "uppercase",
                                                                    color: "#000000",
                                                                    opacity: 0.5,
                                                                    fontSize: "12px",
                                                                }}
                                                            >
                                                                Téléphone
                                                            </p>
                                                            <TextField
                                                                required
                                                                fullWidth
                                                                id="telephone"
                                                                onChange={(e) => setTelephone(e.target.value)}
                                                                value={telephone}
                                                                name="telephone"
                                                                autoComplete="phone"
                                                                sx={{
                                                                    marginBottom: "-10px",
                                                                    background: "#F9FAFF",
                                                                    border: "0.53437 1px solid #D5DCFF",
                                                                    "& .MuiInputBase-root": {
                                                                        height: 30,
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <p
                                                                style={{
                                                                    textAlign: "justify",
                                                                    textTransform: "uppercase",
                                                                    color: "#000000",
                                                                    opacity: 0.5,
                                                                    fontSize: "12px",
                                                                }}
                                                            >
                                                                Adresse
                                                            </p>
                                                            <TextField
                                                                required
                                                                fullWidth
                                                                onChange={(e) => setAdresse(e.target.value)}
                                                                value={adresse}
                                                                id="adresse"
                                                                name="adresse"
                                                                autoComplete="address"
                                                                sx={{
                                                                    marginBottom: "-10px",
                                                                    background: "#F9FAFF",
                                                                    border: "0.53437 1px solid #D5DCFF",
                                                                    "& .MuiInputBase-root": {
                                                                        height: 30,
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <p
                                                                style={{
                                                                    textAlign: "justify",
                                                                    textTransform: "uppercase",
                                                                    color: "#000000",
                                                                    opacity: 0.5,
                                                                    fontSize: "12px",
                                                                }}
                                                            >
                                                                Email
                                                            </p>
                                                            <TextField
                                                                required
                                                                fullWidth
                                                                // id="email"
                                                                name="email"
                                                                onChange={(e) => setEmail(e.target.value)}
                                                                value={email}
                                                                sx={{
                                                                    marginBottom: "-10px",
                                                                    background: "#F9FAFF",
                                                                    border: "0.53437 1px solid #D5DCFF",
                                                                    "& .MuiInputBase-root": {
                                                                        height: 30,
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>

                                                    </Grid>
                                                </>
                                            )}



                                            <div className="boutonreserve22">
                                                {isFalse && (
                                                    <>
                                                        <button
                                                            style={{ marginTop: "150px", marginLeft: "100px" }}
                                                            type="submit"
                                                        >
                                                            voir la facture proforma
                                                        </button>
                                                    </>
                                                )}
                                                <Dialog
                                                    open={openNew}
                                                    onClose={out}
                                                    aria-labelledby="alert-dialog-title"
                                                    aria-describedby="alert-dialog-description"

                                                    PaperProps={{
                                                        sx: {
                                                            width: "100%",
                                                            maxWidth: "720px!important",
                                                            height: "130vh",
                                                            maxHeight: "100vh",
                                                            '@media (max-width: 768px)': {
                                                                width: "80%",
                                                                maxWidth: "100vh",
                                                                height: "80vh"
                                                            },
                                                        }

                                                    }}


                                                >
                                                    <div id="print" >


                                                        <div>

                                                            <div style={{ display: "flex", gridGap: "370px" }} className="printdiv">

                                                                <img src={image} alt="" style={{ width: "100px", height: "50px", marginLeft: "10px" }} />
                                                                <h3 style={{ width: "500px" }}>FACTURE PROFORMA </h3>
                                                            </div>
                                                            <div className="printdesc">
                                                                <div style={{ fontSize: "12px" }} className="printdesc1">
                                                                    <h5>Client</h5>
                                                                    <p>Nom contact : <span className="custom-span" > {prenom + " " + nom} </span></p>
                                                                    <p>Structure : <span className="custom-span" > {nom_structure} </span></p>
                                                                    <p>Email <span className="custom-span" > {email} </span></p>
                                                                    <p> Téléphone: <span className="custom-span" > {telephone} </span> </p>
                                                                    <p> Adresse: <span className="custom-span" > {adresse} </span> </p>

                                                                </div>
                                                                <div style={{ fontSize: "12px" }} className="printdesc2">
                                                                    <h5>SenPharma</h5>
                                                                    <p>Adresse: <span className="custom-span1" >10, boulevard Dial Diop. </span></p>
                                                                    <p className="paddress">Amitié Grand Dakar, Dakar, Sénégal</p>
                                                                    <p>Email <span className="custom-span1" > contact@senpharma.org </span></p>
                                                                    <p> Téléphone: <span className="custom-span1" > +221 77 514 21 76</span> </p>
                                                                    <p tyle={{ fontSize: "12px", fontWeight: "700", marginLeft: "40px" }}>   +221 77 669 21 63 </p>
                                                                </div>

                                                            </div>

                                                            <table className="printable">
                                                                <thead style={{ border: "1px solid #dddd", background: "#FCA13A", color: "#ffff" }}>
                                                                    <tr>
                                                                        <th >Option</th>
                                                                        <th >Offre</th>
                                                                        <th >Montant</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr >
                                                                        <td className="td">{data?.type_service}</td>
                                                                        <td className="td1">{data?.nom_service}</td>
                                                                        <td className="td">{montant_restant.toLocaleString()}</td>
                                                                    </tr>

                                                                </tbody>

                                                            </table>
                                                            <h4 className="printh4" style={{ marginLeft: "380px" }}>TOTAL TTC : <span >{montant_restant.toLocaleString()} Fcfa</span></h4>
                                                            <img src={cachet} alt="" className="printimg" media="screen" />

                                                        </div>

                                                    </div>
                                                    {isSmallScreen ? (
                                                        <>

                                                            <button onClick={onclose} className="printbutton" >Enregistrer</button>
                                                        </>
                                                    ) : (
                                                        <>

                                                            <button onClick={down} className="printbutton" >imprimer</button>
                                                        </>

                                                    )}

                                                </Dialog>


                                            </div>



                                        </div>


                                    </form>
                                </Box>
                            </Container>
                        </div>
                    </div>
                </div>



            </div>


            {/* {(() => {
        if () {
        } else {
          return (

                         
                        )
        }
      })()} */}


            <div className="stepper">
                {isFalse && (
                    <>
                        <Stepper
                            alternativeLabel
                            activeStep={2}
                            connector={<ColorlibConnector />}
                            sx={{
                                width: "30%",
                                margin: "0 auto",
                                backgroundColor: "#fff",
                                color: "#FCA13A",
                                borderRadius: "10px",
                                padding: "8px 10px",
                                '@media (max-width: 768px)': {
                                    width: "80%",
                                },
                                "& .css-1hv8oq8-MuiStepLabel-label.Mui-completed": {
                                    color: "#FCA13A",
                                },
                                "& .Mui-active": {
                                    color: "#68727B",
                                },
                                "& .Mui-disabled": {
                                    color: "#68727B",
                                },
                            }}
                        >
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                                        {label}
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </>
                )}
            </div>
        </div>
    );
};



const countries = [
    "Afghanistan",
    "Albanie",
    "Algérie",
    "Andorre",
    "Angola",
    "Antigua-et-Barbuda",
    "Argentine",
    "Arménie",
    "Australie",
    "Autriche",
    "Azerbaïdjan",
    "Bahamas",
    "Bahreïn",
    "Bangladesh",
    "Barbade",
    "Bélarus",
    "Belgique",
    "Belize",
    "Bénin",
    "Bhoutan",
    "Bolivie",
    "Bosnie-Herzégovine",
    "Botswana",
    "Brésil",
    "Brunéi Darussalam",
    "Bulgarie",
    "Burkina Faso",
    "Burundi",
    "Cambodge",
    "Cameroun",
    "Canada",
    "Cap-Vert",
    "République centrafricaine",
    "Tchad",
    "Chili",
    "Chine",
    "Colombie",
    "Comores",
    "Congo",
    "Costa Rica",
    "Croatie",
    "Cuba",
    "Chypre",
    "République tchèque",
    "République démocratique du Congo",
    "Danemark",
    "Djibouti",
    "Dominique",
    "République dominicaine",
    "Timor-Leste",
    "Équateur",
    "Égypte",
    "Salvador",
    "Guinée équatoriale",
    "Érythrée",
    "Estonie",
    "Éthiopie",
    "Fidji",
    "Finlande",
    "France",
    "Gabon",
    "Gambie",
    "Géorgie",
    "Allemagne",
    "Ghana",
    "Grèce",
    "Grenade",
    "Guatemala",
    "Guinée",
    "Guinée-Bissau",
    "Guyana",
    "Haïti",
    "Saint-Siège",
    "Honduras",
    "Hongrie",
    "Islande",
    "Inde",
    "Indonésie",
    "Iran",
    "Iraq",
    "Irlande",
    "Israël",
    "Italie",
    "Côte d'Ivoire",
    "Jamaïque",
    "Japon",
    "Jordanie",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Koweït",
    "Kirghizistan",
    "République démocratique populaire lao",
    "Lettonie",
    "Liban",
    "Lesotho",
    "Libéria",
    "Libye",
    "Liechtenstein",
    "Lituanie",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaisie",
    "Maldives",
    "Mali",
    "Malte",
    "Îles Marshall",
    "Mauritanie",
    "Maurice",
    "Mexique",
    "Micronésie",
    "Monaco",
    "Mongolie",
    "Monténégro",
    "Maroc",
    "Mozambique",
    "Myanmar",
    "Namibie",
    "Nauru",
    "Népal",
    "Pays-Bas",
    "Nouvelle-Zélande",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Corée du Nord",
    "Macédoine du Nord",
    "Norvège",
    "Oman",
    "Pakistan",
    "Palaos",
    "Panama",
    "Papouasie-Nouvelle-Guinée",
    "Paraguay",
    "Pérou",
    "Philippines",
    "Pologne",
    "Portugal",
    "Qatar",
    "Corée du Sud",
    "Roumanie",
    "Russie",
    "Rwanda",
    "Saint-Kitts-et-Nevis",
    "Sainte-Lucie",
    "Saint-Vincent-et-les-Grenadines",
    "Samoa",
    "Saint-Marin",
    "Sao Tomé-et-Principe",
    "Arabie saoudite",
    "Sénégal",
    "Serbie",
    "Seychelles",
    "Sierra Leone",
    "Singapour",
    "Slovaquie",
    "Slovénie",
    "Îles Salomon",
    "Somalie",
    "Afrique du Sud",
    "Soudan du Sud",
    "Espagne",
    "Sri Lanka",
    "Soudan",
    "Suriname",
    "Swaziland",
    "Suède",
    "Suisse",
    "Syrie",
    "Tadjikistan",
    "Tanzanie",
    "Thaïlande",
    "Togo",
    "Tonga",
    "Trinité-et-Tobago",
    "Tunisie",
    "Turquie",
    "Turkménistan",
    "Tuvalu",
    "Ouganda",
    "Ukraine",
    "Émirats arabes unis",
    "Royaume-Uni",
    "États-Unis",
    "Uruguay",
    "Ouzbékistan",
    "Vanuatu",
    "Venezuela",
    "Viet Nam",
    "Yémen",
    "Zambie",
    "Zimbabwe"

];

export default Devis;

