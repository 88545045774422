import React, { useEffect, useState } from 'react'
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TextField,
    Button,
    Divider,
    Icon,
    Select,
    MenuItem,
    useStepContext,
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete';
import DeleteIcon from '@mui/icons-material/Delete';
import Header from '../../Components/header/Header'
import { Formik, Form, FieldArray } from 'formik'
import api from '../../service/api';
import Notiflix from 'notiflix';
import "./detente.css";

const Detente = () => {
    const [donnees, setDonnees] = useState([]); // State pour stocker les données du tableau
    const [total, setTotal] = useState(0);
    const [data, setData] = useState();
    const [restant, setRestant] = useState(0)


    const getpacks = async () => {
        try {
            const response = await api.getpacks();
            const NewResponse = response.data;
            const nouveauTableauObjets = NewResponse.map((objet) => {
                // Créez une copie de l'objet original en supprimant les propriétés id et nom
                const nouvelObjet = { ...objet };
                delete nouvelObjet.id;
                delete nouvelObjet.createdAt;
                delete nouvelObjet.updatedAt;
                return nouvelObjet;
            });
            setDonnees(nouveauTableauObjets);
            // console.log(response.data)

        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        getpacks();
    }, []);



    const calculmontant = (item) => {
        if (!item?.montant) return item?.prix * item?.quantite || 0
    }
    const calculTotal = (items) => {
        let total = 0;
        items.donnees.forEach((item) => {
            total += calculmontant(item);
            // setTot(total);


        });
        setTotal(total)
        return total;
    };



    const handleSubmit = async (values) => {
        values.total = total;
        values.restant = total;
        values.nom = values.prenom + " " + values.name;


        try {
            const response = await api.createdetente(values)
            setData(response.data)
            Notiflix.Loading.standard("Création en cours...", { svgColor: "#FCA13A" });
            window.location.href = "/partenaire/FinReservation";
            localStorage.setItem("response", JSON.stringify(response.data.message));


        } catch (error) {
            // console.log(error)
        }



    };



    const [isFalse, setIsFalse] = useState(true);

    const handleToggle = () => {
        setIsFalse(!isFalse);
        // console.log(isFalse);
    };


    const [isSmallScreen, setIsSmallScreen] = useState(false);


    useEffect(() => {
        function handleResize() {
            // Mettre à jour isSmallScreen en fonction de la largeur de l'écran
            setIsSmallScreen(window.innerWidth <= 768);
        }

        // Écouter les changements de taille d'écran
        window.addEventListener('resize', handleResize);

        // Appel initial de la fonction de gestion de la taille de l'écran
        handleResize();

        // Nettoyage de l'écouteur d'événements lors du démontage du composant
        return () => {
            window.removeEventListener('resize', handleResize);
        }


    }, []);


    return (
        <div className="containe">
            <Header handleMenu={handleToggle} />
            <div style={{ paddingTop: "100px" }}>
                <div className='back'>

                    <Formik
                        initialValues={{
                            telephone: '',
                            email: '',
                            total: 0,
                            donnees,
                            // :
                            //  [
                            //     {
                            //         pack: '',
                            //         quantite: '',
                            //         prix: '',
                            //     }
                            // ],

                        }}
                        onSubmit={handleSubmit}
                        render={({ values, handleChange, setFieldValue, handleSubmit }) => {
                            // console.log(values);
                            return (
                                <Form onSubmit={handleSubmit} className='Form'>
                                    {isFalse && (
                                        <>
                                            <p style={{ paddingTop: "30px", fontSize: "20px" }} className='Ptittle'>Réservation Packs</p>
                                            <Divider sx={{
                                                marginTop: "50px"
                                            }} />

                                            <div className='input'>
                                                <div className='inputgrid'>
                                                    <div style={{ display: "flex", gridGap: "20px" }} className='text1'>
                                                        <p style={{ fontSize: "12px" }}>Prénom</p>
                                                        <TextField
                                                            name='prenom'
                                                            value={values.prenom}
                                                            onChange={handleChange}
                                                            required
                                                            fullWidth
                                                            autoFocus
                                                            size="small"
                                                            sx={{
                                                                '@media (max-width: 768px)': {
                                                                    // width: "70%",
                                                                    marginLeft: "20px",

                                                                },
                                                            }}
                                                        />
                                                    </div>
                                                    <div style={{ display: "flex", gridGap: "20px" }} className='text2'>
                                                        <p style={{ fontSize: "12px" }} >Nom</p>
                                                        <TextField
                                                            name="name"
                                                            value={values.name}
                                                            onChange={handleChange}
                                                            required
                                                            fullWidth
                                                            autoFocus
                                                            size="small"
                                                            sx={{
                                                                '@media (max-width: 768px)': {
                                                                    // width: "0%",
                                                                    marginLeft: "35px",

                                                                },
                                                            }}

                                                        />
                                                    </div>
                                                </div>

                                                <div className='inputgrid1'>
                                                    <div style={{ display: "flex", gridGap: "20px" }}>
                                                        <p style={{ fontSize: "12px" }}>Téléphone</p>
                                                        <TextField
                                                            name='telephone'
                                                            value={values.telephone}
                                                            onChange={handleChange}
                                                            required
                                                            fullWidth
                                                            autoFocus
                                                            size="small"
                                                            sx={{
                                                                '@media (max-width: 768px)': {
                                                                    width: "70%",
                                                                    // marginLeft: "30px",

                                                                },
                                                            }}
                                                        />
                                                    </div>
                                                    <div style={{ display: "flex", gridGap: "20px" }} className='text2'>
                                                        <p style={{ fontSize: "12px" }} >Email</p>
                                                        <TextField
                                                            name="email"
                                                            value={values.email}
                                                            onChange={handleChange}
                                                            required
                                                            fullWidth
                                                            autoFocus
                                                            size="small"
                                                            sx={{
                                                                '@media (max-width: 768px)': {
                                                                    // width: "70%",
                                                                    marginLeft: "28px",

                                                                },
                                                            }}
                                                        />
                                                    </div>


                                                </div>
                                            </div>
                                            <Divider sx={{ marginTop: "150px" }} />
                                            <FieldArray
                                                name="donnees"
                                                render={arrayHelpers => (

                                                    <div style={{ width: "100%" }} >
                                                        <Table >
                                                            <TableHead sx={{}} >
                                                                <TableRow sx={{}} >
                                                                    <TableCell sx={{
                                                                        fontSize: "18px", color: "#000",
                                                                        '@media (max-width: 768px)': {
                                                                            fontSize: "11px",
                                                                            padding: "5px 15px"


                                                                        },
                                                                    }}>Packs</TableCell>
                                                                    <TableCell sx={{
                                                                        fontSize: "18px", color: "#000",
                                                                        '@media (max-width: 768px)': {
                                                                            fontSize: "11px",
                                                                            padding: "5px 15px"


                                                                        },
                                                                    }} >Quantité </TableCell>
                                                                    <TableCell sx={{
                                                                        fontSize: "18px", color: "#000",
                                                                        '@media (max-width: 768px)': {
                                                                            display: "none"


                                                                        },
                                                                    }} >Prix</TableCell>
                                                                    <TableCell sx={{
                                                                        fontSize: "18px", color: "#000", width: "200px",
                                                                        '@media (max-width: 768px)': {
                                                                            fontSize: "11px",
                                                                            padding: "5px 15px"

                                                                        },
                                                                    }}>Montant</TableCell>


                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>

                                                                {values?.donnees?.map((item, ind) => (
                                                                    <TableRow key={ind}>

                                                                        <TableCell>
                                                                            <Autocomplete
                                                                                className="w-full"
                                                                                size="small"
                                                                                options={donnees}
                                                                                getOptionLabel={(option) => option.pack}
                                                                                renderInput={(params) => (
                                                                                    <TextField
                                                                                        {...params}
                                                                                        variant="outlined"
                                                                                        fullWidth


                                                                                        sx={{
                                                                                            width: "400px",
                                                                                            '@media (max-width: 768px)': {
                                                                                                width: "90px",
                                                                                                "& .MuiInputBase-root": {
                                                                                                    height: "30px",
                                                                                                    "&.MuiInputBase-sizeSmall .MuiAutocomplete-input": {
                                                                                                        fontSize: "8px"
                                                                                                    }

                                                                                                }

                                                                                            },
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                                onChange={(event, newValue) => {
                                                                                    handleChange({
                                                                                        target: {
                                                                                            name: `donnees[${ind}]`,
                                                                                            value: newValue,
                                                                                        },
                                                                                    })

                                                                                }}

                                                                            />
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <TextField
                                                                                name={`donnees[${ind}].quantite`}
                                                                                size="small"
                                                                                variant="outlined"
                                                                                type="number"
                                                                                fullWidth
                                                                                value={item?.quantite || ''}
                                                                                onChange={handleChange}
                                                                                sx={{
                                                                                    width: "350px",
                                                                                    '@media (max-width: 768px)': {
                                                                                        width: "90px",
                                                                                        "& .MuiInputBase-root": {
                                                                                            height: "30px",

                                                                                        }

                                                                                    },
                                                                                }}
                                                                                inputProps={{
                                                                                    min: 1,
                                                                                    max: 100,
                                                                                    step: 1,
                                                                                }}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell

                                                                            className="p-0"
                                                                            align="left"
                                                                        >
                                                                            <TextField
                                                                                name={`donnees[${ind}].prix`}
                                                                                size="small"
                                                                                variant="outlined"
                                                                                type="number"
                                                                                fullWidth
                                                                                value={item?.prix}
                                                                                // onChange={handleChange}
                                                                                inputProps={{
                                                                                    min: 1,
                                                                                    step: 1,
                                                                                }}
                                                                                sx={{
                                                                                    width: "350px",
                                                                                    '@media (max-width: 768px)': {
                                                                                        display: "none"

                                                                                    },
                                                                                }}
                                                                            />
                                                                        </TableCell>

                                                                        {isSmallScreen ? (
                                                                            <>
                                                                                <TableCell sx={{
                                                                                    borderBottom: "none",
                                                                                    position: "relative", right: "80px", top: "15px"
                                                                                    // display: "flex", gridGap: "5px", marginTop: "10px"
                                                                                    // '@media (max-width: 768px)': {
                                                                                    //     display:"block",marginBottom:"20px",marginLeft:"-100px",alignItems:"center"
                                                                                    // },
                                                                                }}>
                                                                                    {calculmontant(item).toLocaleString()}   <br />
                                                                                    <DeleteIcon onClick={() => arrayHelpers.remove(ind)}
                                                                                        sx={{ color: "#FCA13A", position: "relative", top: "10px" }} />



                                                                                </TableCell>
                                                                            </>
                                                                        ) : (

                                                                            <>
                                                                                <TableCell sx={{
                                                                                    display: "flex", gridGap: "5px", marginTop: "15px"
                                                                                    // '@media (max-width: 768px)': {
                                                                                    //     display:"block",marginBottom:"20px",marginLeft:"-100px",alignItems:"center"
                                                                                    // },
                                                                                }}>
                                                                                    {calculmontant(item).toLocaleString()}   <br />
                                                                                    <DeleteIcon onClick={() => arrayHelpers.remove(ind)}
                                                                                        sx={{ color: "#FCA13A" }} />



                                                                                </TableCell>
                                                                            </>

                                                                        )}



                                                                    </TableRow>

                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                        <div style={{ marginTop: "70px", }} className='bouton'>
                                                            <a href
                                                                style={{

                                                                    padding: "15px 20px", background: "#FCA13A", border: "none", color: "#fff", position: "relative", right: "500px"
                                                                }}
                                                                size="small"
                                                                onClick={() => arrayHelpers.push({})}
                                                            >
                                                                + Ajouter une réservation
                                                            </a>
                                                        </div>

                                                        {isSmallScreen ? (
                                                            <>
                                                                <div >
                                                                    <TableCell sx={{
                                                                        display: "flex", gridGap: "100px",

                                                                    }}>
                                                                        <p className="total" >Total TTC: </p>
                                                                        <h5 className="m-0">
                                                                            {calculTotal(values).toLocaleString()} Fcfa
                                                                        </h5>
                                                                    </TableCell>
                                                                </div>
                                                            </>

                                                        ) : (
                                                            <>
                                                                <div  >
                                                                    <TableCell sx={{
                                                                        display: "flex", gridGap: "100px",

                                                                    }}>
                                                                        <p className='total' style={{ marginTop: "-100px", fontSize: "20px", fontWeight: "700", }}>Total TTC: </p>
                                                                        <h5 className="m-0" style={{ marginTop: "-100px", fontSize: "20px", fontWeight: "700" }}>
                                                                            {calculTotal(values).toLocaleString()} Fcfa
                                                                        </h5>
                                                                    </TableCell>
                                                                </div>
                                                            </>
                                                        )}

                                                    </div>
                                                )}
                                            />

                                            <div style={{ marginTop: "20px" }}>
                                                <button
                                                    style={{
                                                        marginTop: "40px",
                                                        padding: "10px 20px", background: "#FCA13A", border: "none", color: "#fff"
                                                    }}
                                                    type="submit"
                                                >
                                                    Envoyer
                                                </button>

                                            </div> <br />
                                        </>
                                    )
                                    }

                                </Form >
                            )
                        }}


                    />

                </div>
            </div>
        </div >

    )
}

export default Detente

