import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Navbar from '../Components/navbar/Navbar';
import api from '../service/api';
import Notiflix from 'notiflix';
import { DataGrid } from "@mui/x-data-grid";
import Dialog from '@mui/material/Dialog';



const Description = () => {
    const [selectedDate, setSelectedDate] = useState('');
    const [data, setData] = useState([]);
    const [type_description, setType_description] = useState([]);
    const [description, setDescription] = useState([]);

    const datagridSx = {
        border: "none",

        "& .MuiDataGrid-main": { border: "none" },
        '& div[data-rowIndex][role="row"]:nth-of-type(5n-4)': {
            fontSize: 12,

        },

        "& .MuiDataGrid-columnHeaders": {
            color: "#B5BFC9",
        },

        "& .MuiDataGrid-footerContainer": {
            display: "none",
            border: "none"
        }

    };

    // const handleCellClick = (param, event) => {
    //   console.log(param.row);

    //   if (param.colIndex === 2) {
    //     event.stopPropagation();
    //   }
    // };

    const handleRowClick = (param, event) => {
        console.log(param);
    };

    const columns = [

        {
            field: "id",
            headerName: "ID",
            width: 150,
            // renderHeader: (params) => {
            //     return <RenderHeaderComponent {...params} />
            // },
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            color: "#000",
                            fontSize: 12,
                            fontWeight: 600
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            }
        },
        {
            field: "type_description",
            headerName: "Type description",
            width: 200,
            // renderHeader: (params) => {
            //     return <RenderHeaderComponent {...params} />
            // },
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            color: "#000",
                            fontSize: 12,
                            fontWeight: 600
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            }
        },

        {
            field: "description",
            headerName: "Description",
            width: 250,
            // renderHeader: (params) => {
            //     return <RenderHeaderComponent {...params} />
            // },

            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            color: "#68727B",
                            fontSize: 12,
                            fontWeight: 400
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            }

        },
        {
            field: "Action",
            headerName: "Action",
            sortable: false,
            disableColumnMenu: true,
            minWidth: 200,
            flex: 1,
            renderCell: (params) => {
                return <RenderCellsComponent {...params} />
            }


        },
    ]


    const listDescription = async (e) => {
        try {
            const responseData = await api.getDesc();
            setDescription(responseData.data);

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        listDescription();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const inputData = {
            type_description: type_description,
            description: selectedDate,
        }

        // console.log(inputData)

        try {
            const response = await api.createDesc(inputData)
            if (response) {
                setData(response.data);
                Notiflix.Loading.standard("création en cours...", { svgColor: "#FCA13A" });
                window.location.reload();

            }
        } catch (error) {
            console.log(error)
        }
    };





    const desc = ["partenaire", "detente"];
    return (
        <div>
            <Navbar />
            <div
                className="divtitle"
                style={{ color: "#0F0F0F", position: "absolute" }}
            >
                <p>Description</p>
            </div>

            <div style={{ position: "relative", top: "-570px", left: "290px", textAlign: "justify" }}>
                <h4 style={{ marginLeft: "300px", fontSize: "30px" }}>Evénements Senpharma</h4>
                <form onSubmit={handleSubmit}>
                    <div style={{ marginLeft: "200px", marginTop: "50px" }}>
                        <div>
                            <p>Type de description</p>
                            <Autocomplete
                                id="date-select-demo"
                                options={desc}
                                required
                                getOptionLabel={(option) => option}
                                value={type_description}
                                onChange={(event, newValue) => {
                                    setType_description(newValue);
                                }}
                                sx={{ width: "500px" }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </div>
                        <div>
                            <p >Choisissez les dates et le lieu</p>
                            <TextField

                                onChange={(e) => {
                                    setSelectedDate(e.target.value)

                                }}
                                value={selectedDate}
                                name="date"
                                required
                                sx={{ width: "500px" }}
                            />

                        </div>


                        <div>
                            <button style={{ padding: "15px 20px", background: "#FCA13A", border: "none", color: "#fff", textDecoration: "none", marginTop: "20px", marginLeft: "200px" }}>Sauvegarder</button>

                        </div>

                    </div>
                </form>


                <div style={{ height: 1000, width: "100%", marginTop: "80px" }} className="no-print">
                    <DataGrid
                        //toolBar={<Toolbar sx={{ backgroundColor: "blue" }} />}

                        sx={datagridSx}
                        rows={description}
                        columns={columns}
                        pageSize={4}
                        checkboxSelection
                        // onCellClick={handleCellClick}
                        onRowClick={handleRowClick}

                    />

                </div>

            </div>

        </div>
    );
};

const RenderCellsComponent = (params) => {
    const [ouvert, setOuvert] = useState(false);
    const handleOpen = () => {
        setOuvert(true);

    };

    const close = () => {
        setOuvert(false);
    };

    const fermer = () => {
        setOuvert(false);
    };

    const [opened, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
        recupDesc();
    };
    const handleClosed = () => {
        setOpen(false);
    };

    const [selectedDate, setSelectedDate] = useState('');
    const [type_description, setType_description] = useState([]);
    const [description, setDescription] = useState([]);
    const [data, setData] = useState([]);
    const desc = ["partenaire", "detente"];

    const service = params.row;

    const deleteDesc = async () => {
        const id = service.id;
        try {
            const resp = await api.deleteDesc(id);
            Notiflix.Loading.standard("Suppression en cours...", { svgColor: "#FCA13A" });
            window.location.reload();

        } catch (error) {
            // console.log(error)
        }

    };

    const recupDesc = async () => {
        const id = service.id;
        try {
            const resp = await api.recupDesc(id);
            // Notiflix.Loading.standard("Suppression en cours...", { svgColor: "#FCA13A" });
            // window.location.reload();
            setSelectedDate(resp.data.description); // Pré-remplissez selectedDate avec la valeur récupérée
            setType_description(resp.data.type_description); // Pré-remplissez typeDescription avec la valeur récupérée

        } catch (error) {
            // console.log(error)
        }

    };

    useEffect(() => {
        recupDesc();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const inputData = {
            type_description: type_description,
            description: selectedDate,
        }
        const id = service.id


        try {
            const response = await api.updateDesc(id, inputData)
            if (response) {
                setData(response.data);
                setDescription(response.data.description);
                setType_description(response.data.type_description);
                Notiflix.Loading.standard("modification en cours...", { svgColor: "#FCA13A" });
                window.location.reload();

            }
        } catch (error) {
            console.log(error)
        }
    };




    return (
        <div>
            <button style={{ padding: "10px 20px", background: "#b1e0e9", border: "none", color: "#fff" }} onClick={handleClickOpen}>modifier</button>
            <Dialog
                open={opened}
                onClose={handleClosed}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    sx: {
                        width: "100%",
                        maxWidth: "720px!important",
                        height: "50vh"
                    }
                }}

            >
                <form onSubmit={handleSubmit}>
                    <div style={{ marginLeft: "100px", marginTop: "30px" }}>
                        <>
                            <div>
                                <p>Type de description</p>
                                <Autocomplete
                                    id="date-select-demo"
                                    options={desc}
                                    required
                                    getOptionLabel={(option) => option}
                                    value={service?.type_description || ''}
                                    onChange={(event, newValue) => {
                                        setType_description(newValue);
                                    }}
                                    sx={{ width: "500px" }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </div>
                            <div>
                                <p >Choisissez les dates et le lieu</p>
                                <TextField
                                    value={selectedDate}
                                    onChange={(e) => {
                                        setSelectedDate(e.target.value)
                                    }}
                                    name="date"
                                    required
                                    sx={{ width: "500px" }}
                                />

                            </div>
                        </>



                        <div>
                            <button style={{ padding: "15px 20px", background: "#FCA13A", border: "none", color: "#fff", textDecoration: "none", marginTop: "20px", marginLeft: "200px" }}>Sauvegarder</button>

                        </div>

                    </div>
                </form>

            </Dialog>
            <button style={{ marginLeft: "20px", padding: "10px 20px", background: "#FCA13A", border: "none", color: "#fff", }} onClick={handleOpen} >supprimer</button>

            <Dialog
                open={ouvert}
                onClose={close}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    sx: {
                        width: "100%",
                        maxWidth: "720px!important",
                        height: "50vh"
                    }
                }}

            >
                <p style={{ textAlign: "center", padding: "30px", fontSize: "35px" }}>
                    Voulez-vous vraiement supprimer cette réservation ?

                </p>
                <div style={{ display: "flex", gap: "20px", marginLeft: "270px" }}>
                    <button onClick={deleteDesc} style={{ padding: "15px 20px", backgroundColor: "#b1e0e9", border: "none", color: "#fff" }}>Oui</button>
                    <button onClick={fermer} style={{ padding: "15px 20px", background: "linear-gradient(83.26deg, #F5841F 17.33%, #FFC380 83.63%)", border: "none", color: "#fff" }}>Annuler</button>
                </div>
            </Dialog>
        </div>
    )
}

export default Description;