import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from "@mui/material/TextField";
import { MuiTelInput } from "mui-tel-input";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Header from "../../Components/header/Header";
import api from "../../service/api";
import "./style.css";
import { Autocomplete } from "@mui/material";

const Participant = () => {
  const [inputData, setInputData] = useState();
  const [montant_restant, setMontant_restant] = useState(0);
  const [montant, setMontant] = useState(0);
  const [kits, setKits] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [isFalse, setIsFalse] = useState(true);

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    console.log(inputValue);
  };

  const handleToggle = () => {
    setIsFalse(!isFalse);
    // console.log(isFalse);
  }


  const handleInput = (e) => {
    setInputData({
      ...inputData,
      [e.target.name]: e.target.value,
    });
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
   
    inputData.pays = inputValue;
    if (inputData.kits.trim() === "") {
      alert("Veuillez sélectionner une option pour les kits.");
    }
    if (inputData.kits === 'avec kit') {
      inputData.prix_kits = 20000;
      inputData.montant_restant = 20000;

    } else {
      inputData.prix_kits = 0;
      inputData.montant_restant = 0;
      inputData.statut_paiement = "Payée";
    }

   
    console.log(inputData)
    try {
      const resp = await api.postuserstand(inputData);
      // console.log(resp.data)
      localStorage.setItem("response", JSON.stringify(resp.data))
      window.location.href = "/partenaire/FinReservation"

    } catch (error) {
      console.log(error)
    }


  };
  // const [isResponsiveclose, setIsResponsiveclose] = useState(true);



  return (
    <div className="containe">
      <Header handleMenu={handleToggle} />
      <div className="form" style={{ paddingTop: "90px" }}>
        <Container
          component="main"
          className="comp"
          sx={{
            width: 330,
            backgroundColor: "#fff",
            padding: "10px 40px",
            borderRadius: "20px",
            '@media (max-width: 768px)': {
              padding: "5px 10px",
              width: 300,
              height: "130vh"
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}

          >
            <Typography
              component="h1"
              variant="h5"
              sx={{
                color: "#2E2E2E", fontSize: "40px", fontWeight: "600",
                '@media (max-width: 768px)': {
                  fontSize: '25px',
                },
              }}
            >
              Participant{" "}

            </Typography>

            <p
              style={{
                color: "#2E2E2E",
                fontSize: "20px",
                fontWeight: 600,
                marginTop: "-5px"

              }}
            >
              Formulaire
            </p>

            <form onSubmit={handleSubmit} className="formStyle"
            >

              <div className="item" style={{}}>
                <Grid
                  container
                  spacing={1}
                  sx={{ textTransform: "uppercase", }}
                >
                  {isFalse && (
                    <>
                      <Grid item xs={12} sm={6} >
                        <p
                          style={{
                            textAlign: "justify",
                            textTransform: "uppercase",
                            color: "#000000",
                            fontSize: "12px",
                            opacity: 0.5,

                          }}
                        >
                          Prénom
                        </p>
                        <TextField
                          onChange={handleInput}
                          autoComplete="prenom"
                          name="prenom"
                          required
                          fullWidth
                          id="prenom"
                          autoFocus
                          sx={{
                            zIndex: "100px",
                            marginBottom: "-10px",
                            background: "#F9FAFF",
                            border: "0.53437 1px solid #D5DCFF",
                            '@media (max-width: 768px)': {
                              marginBottom: "-5px",
                            },
                            "& .MuiInputBase-root": {
                              height: 30,

                            },
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <p
                          style={{
                            textAlign: "justify",
                            textTransform: "uppercase",
                            color: "#000000",
                            fontSize: "12px",
                            opacity: 0.5,
                          }}
                        >
                          nom
                        </p>
                        <TextField
                          required
                          fullWidth
                          onChange={handleInput}
                          id="nom"
                          name="nom"
                          autoComplete="nom"
                          sx={{
                            zIndex: "100px",
                            marginBottom: "-10px",
                            background: "#F9FAFF",
                            border: "0.53437 1px solid #D5DCFF",
                            '@media (max-width: 768px)': {
                              marginBottom: "-5px",
                            },
                            "& .MuiInputBase-root": {
                              height: 30,

                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <p
                          style={{
                            textAlign: "justify",
                            textTransform: "uppercase",
                            color: "#000000",
                            fontSize: "12px",
                            opacity: 0.5,
                          }}
                        >
                          Structure
                        </p>
                        <TextField
                          required
                          fullWidth
                          onChange={handleInput}
                          id="nom_structure"
                          name="nom_structure"
                          autoComplete="nom"
                          sx={{
                            zIndex: "100px",
                            marginBottom: "-10px",
                            background: "#F9FAFF",
                            border: "0.53437 1px solid #D5DCFF",
                            '@media (max-width: 768px)': {
                              marginBottom: "-5px",
                            },
                            "& .MuiInputBase-root": {
                              height: 30,

                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} >
                        <p
                          style={{
                            textAlign: "justify",
                            textTransform: "uppercase",
                            color: "#000000",
                            opacity: 0.5,
                            fontSize: "12px",
                          }}
                        >
                          Pays
                        </p>
                        <Autocomplete
                          id="country-select-demo"
                          options={countries}
                          autoHighlight
                          inputValue={inputValue}
                          onInputChange={handleInputChange}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              fullWidth
                              name="pays"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                              }}
                              sx={{
                                marginBottom: "-10px",
                                border: "0.53437 1px solid #D5DCFF",
                                "& .MuiInputBase-root": {
                                  height: 30,
                                  width: "280px",
                                  background: "#F9FAFF",
                                  '@media (max-width: 768px)': {
                                    height: 30,

                                  },

                                },
                                "& .MuiAutocomplete-input": {
                                  marginTop: "-15px"

                                }
                              }}
                            />
                          )}
                        />

                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <p
                          style={{
                            textAlign: "justify",
                            textTransform: "uppercase",
                            color: "#000000",
                            opacity: 0.5,
                            fontSize: "12px",
                          }}
                        >
                          Région
                        </p>
                        <TextField
                          autoComplete="region"
                          name="region"
                          onChange={handleInput}
                          required
                          fullWidth
                          id="region"
                          autoFocus
                          sx={{
                            marginBottom: "-10px",
                            background: "#F9FAFF",
                            border: "0.53437 1px solid #D5DCFF",
                            "& .MuiInputBase-root": {
                              height: 30,

                            },
                          }}
                        />
                      </Grid>


                      <Grid item xs={12} sm={6}>
                        <p
                          style={{
                            textAlign: "justify",
                            textTransform: "uppercase",
                            color: "#000000",
                            opacity: 0.5,
                            fontSize: "12px",
                          }}
                        >
                          ville
                        </p>
                        <TextField
                          autoComplete="ville"
                          name="ville"
                          onChange={handleInput}
                          required
                          fullWidth
                          id="ville"
                          autoFocus
                          sx={{
                            marginBottom: "-10px",
                            background: "#F9FAFF",
                            border: "0.53437 1px solid #D5DCFF",
                            "& .MuiInputBase-root": {
                              height: 30,

                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <p
                          style={{
                            textAlign: "justify",
                            textTransform: "uppercase",
                            color: "#000000",
                            opacity: 0.5,
                            fontSize: "12px",
                          }}
                        >
                          Téléphone
                        </p>
                        <TextField
                          required
                          fullWidth
                          id="telephone"
                          onChange={handleInput}
                          name="telephone"
                          autoComplete="phone"
                          sx={{
                            marginBottom: "-10px",
                            background: "#F9FAFF",
                            border: "0.53437 1px solid #D5DCFF",
                            "& .MuiInputBase-root": {
                              height: 30,

                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <p
                          style={{
                            textAlign: "justify",
                            textTransform: "uppercase",
                            color: "#000000",
                            opacity: 0.5,
                            fontSize: "12px",
                          }}
                        >
                          Adresse
                        </p>
                        <TextField
                          required
                          fullWidth
                          onChange={handleInput}
                          id="adresse"
                          name="adresse"
                          autoComplete="address"
                          sx={{
                            marginBottom: "-10px",
                            background: "#F9FAFF",
                            border: "0.53437 1px solid #D5DCFF",
                            "& .MuiInputBase-root": {
                              height: 30,

                            },
                          }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <p
                          style={{
                            textAlign: "justify",
                            textTransform: "uppercase",
                            color: "#000000",
                            opacity: 0.5,
                            fontSize: "12px",
                          }}
                        >
                          Email
                        </p>
                        <TextField
                          required
                          fullWidth
                          // id="email"
                          name="email"
                          onChange={handleInput}
                          autoComplete="email"
                          sx={{
                            marginBottom: "-10px",
                            background: "#F9FAFF",
                            border: "0.53437 1px solid #D5DCFF",
                            "& .MuiInputBase-root": {
                              height: 30,

                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sx={{ marginTop: "20px", }}>
                        <RadioGroup
                          aria-labelledby="demo-error-radios"
                          name="kits"
                          onChange={handleInput}
                          required


                        >
                          <FormControlLabel
                            value="avec kit"
                            control={<Radio />}
                            label="Avec kit 20.000 (sac, Bloc-notes, Book, Stylo)"
                            sx={{
                              fontSize: "12px",
                              ".MuiFormControlLabel-label": {
                                fontSize: "12px",
                                '@media (max-width: 768px)': {

                                },
                              }
                            }} />
                          <FormControlLabel
                            value="sans kit"
                            control={<Radio />} label="sans kit"
                            sx={{
                              fontSize: "12px",
                              ".MuiFormControlLabel-label": {
                                fontSize: "12px",

                              }
                            }} />
                        </RadioGroup>


                      </Grid>

                    </>
                  )}
                </Grid>



                <div >
                  <button style={{ padding: "15px 20px", marginTop: "50px", background: "#FCA13A", border: "none", color: "#fff" }}
                    type="submit"

                  >
                    Réserver
                  </button>
                </div>



              </div>


            </form>
          </Box>
        </Container>
      </div>
    </div>
  );
}

export default Participant;


const countries = [
  "Afghanistan",
  "Albanie",
  "Algérie",
  "Andorre",
  "Angola",
  "Antigua-et-Barbuda",
  "Argentine",
  "Arménie",
  "Australie",
  "Autriche",
  "Azerbaïdjan",
  "Bahamas",
  "Bahreïn",
  "Bangladesh",
  "Barbade",
  "Bélarus",
  "Belgique",
  "Belize",
  "Bénin",
  "Bhoutan",
  "Bolivie",
  "Bosnie-Herzégovine",
  "Botswana",
  "Brésil",
  "Brunéi Darussalam",
  "Bulgarie",
  "Burkina Faso",
  "Burundi",
  "Cambodge",
  "Cameroun",
  "Canada",
  "Cap-Vert",
  "République centrafricaine",
  "Tchad",
  "Chili",
  "Chine",
  "Colombie",
  "Comores",
  "Congo",
  "Costa Rica",
  "Croatie",
  "Cuba",
  "Chypre",
  "République tchèque",
  "République démocratique du Congo",
  "Danemark",
  "Djibouti",
  "Dominique",
  "République dominicaine",
  "Timor-Leste",
  "Équateur",
  "Égypte",
  "Salvador",
  "Guinée équatoriale",
  "Érythrée",
  "Estonie",
  "Éthiopie",
  "Fidji",
  "Finlande",
  "France",
  "Gabon",
  "Gambie",
  "Géorgie",
  "Allemagne",
  "Ghana",
  "Grèce",
  "Grenade",
  "Guatemala",
  "Guinée",
  "Guinée-Bissau",
  "Guyana",
  "Haïti",
  "Saint-Siège",
  "Honduras",
  "Hongrie",
  "Islande",
  "Inde",
  "Indonésie",
  "Iran",
  "Iraq",
  "Irlande",
  "Israël",
  "Italie",
  "Côte d'Ivoire",
  "Jamaïque",
  "Japon",
  "Jordanie",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Koweït",
  "Kirghizistan",
  "République démocratique populaire lao",
  "Lettonie",
  "Liban",
  "Lesotho",
  "Libéria",
  "Libye",
  "Liechtenstein",
  "Lituanie",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaisie",
  "Maldives",
  "Mali",
  "Malte",
  "Îles Marshall",
  "Mauritanie",
  "Maurice",
  "Mexique",
  "Micronésie",
  "Monaco",
  "Mongolie",
  "Monténégro",
  "Maroc",
  "Mozambique",
  "Myanmar",
  "Namibie",
  "Nauru",
  "Népal",
  "Pays-Bas",
  "Nouvelle-Zélande",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Corée du Nord",
  "Macédoine du Nord",
  "Norvège",
  "Oman",
  "Pakistan",
  "Palaos",
  "Panama",
  "Papouasie-Nouvelle-Guinée",
  "Paraguay",
  "Pérou",
  "Philippines",
  "Pologne",
  "Portugal",
  "Qatar",
  "Corée du Sud",
  "Roumanie",
  "Russie",
  "Rwanda",
  "Saint-Kitts-et-Nevis",
  "Sainte-Lucie",
  "Saint-Vincent-et-les-Grenadines",
  "Samoa",
  "Saint-Marin",
  "Sao Tomé-et-Principe",
  "Arabie saoudite",
  "Sénégal",
  "Serbie",
  "Seychelles",
  "Sierra Leone",
  "Singapour",
  "Slovaquie",
  "Slovénie",
  "Îles Salomon",
  "Somalie",
  "Afrique du Sud",
  "Soudan du Sud",
  "Espagne",
  "Sri Lanka",
  "Soudan",
  "Suriname",
  "Swaziland",
  "Suède",
  "Suisse",
  "Syrie",
  "Tadjikistan",
  "Tanzanie",
  "Thaïlande",
  "Togo",
  "Tonga",
  "Trinité-et-Tobago",
  "Tunisie",
  "Turquie",
  "Turkménistan",
  "Tuvalu",
  "Ouganda",
  "Ukraine",
  "Émirats arabes unis",
  "Royaume-Uni",
  "États-Unis",
  "Uruguay",
  "Ouzbékistan",
  "Vanuatu",
  "Venezuela",
  "Viet Nam",
  "Yémen",
  "Palestine",
  "Zambie",
  "Zimbabwe"

];