import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../Components/header/Header";
import "./style.css";
import api from "../../service/api";



const Partenaire = () => {
  const [data, setData] = useState([]);
  const [isFalse, setIsFalse] = useState(true);

  const getDesc = async () => {
    try {

      const response = await api.getDesc();
      // setData(data.data);
      const resp = Object.values(response.data).filter(user => user.type_description === "partenaire");
      setData(resp);
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getDesc();
  }, []);


  const handleToggle = () => {
    setIsFalse(!isFalse);
    // console.log(isFalse);
  }


  return (
    <div className="containe">
      <Header handleMenu={handleToggle} />

      <div className="main">
        <div className="main-container">
          <h1>Devenez partenaire</h1>
          {data.map((item, i) =>

            <p key={i}>
              Au salon international du médicament des dispositifs médicaux et de la parapharmacie <br />{" "}
              qui se tiendra les {item?.description}
            </p>)}
        </div>

        <div className="divBouton">
          <Link
            to="/partenaire/reserve"
            className="btn1"
            style={{ textTransform: "uppercase" }}
          >
            Réservez Stand
          </Link>
          <Link
            to="/partenaire/sponsor"
            className="btn2"
            style={{ textTransform: "uppercase" }}
          >
            Offres Sponsoring
          </Link>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />


      </div>
    </div>
  );
};

export default Partenaire;
