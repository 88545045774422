import React, { useEffect, useState } from "react";
import { DataGrid, GridValueGetterParams } from "@mui/x-data-grid";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { IconButton, Table } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import image from '../../images/logo1.png';
import cachet from '../../images/cachet_senpharma.png';
import Notiflix from "notiflix";
import api from "../../service/api";






export default function DataGridDemo() {
    const [tableData, setTableData] = useState([])

    const datagridSx = {
        border: "none",

        "& .MuiDataGrid-main": { border: "none" },
        '& div[data-rowIndex][role="row"]:nth-of-type(5n-4)': {
            fontSize: 12,

        },

        "& .MuiDataGrid-columnHeaders": {
            color: "#B5BFC9",
        },

        "& .MuiDataGrid-footerContainer": {
            display: "none",
            border: "none"
        }

    };

    // const handleCellClick = (param, event) => {
    //   console.log(param.row);

    //   if (param.colIndex === 2) {
    //     event.stopPropagation();
    //   }
    // };

    const handleRowClick = (param, event) => {
        console.log(param);
    };

    const columns = [

        {
            field: "id",
            headerName: "ID",
            width: 50,
            renderHeader: (params) => {
                return <RenderHeaderComponent {...params} />
            },
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            color: "#000",
                            fontSize: 12,
                            fontWeight: 600
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            }
        },
        {
            field: "nom",
            headerName: "Nom",
            width: 130,
            renderHeader: (params) => {
                return <RenderHeaderComponent {...params} />
            },
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            color: "#000",
                            fontSize: 12,
                            fontWeight: 600
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            }
        },

        {
            field: "email",
            headerName: "Email",
            width: 160,
            renderHeader: (params) => {
                return <RenderHeaderComponent {...params} />
            },

            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            color: "#68727B",
                            fontSize: 12,
                            fontWeight: 400
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            }

        },
        {
            field: "telephone",
            headerName: "Tel",
            //width: 90,
            minWidth: 80,
            flex: 1,
            renderHeader: (params) => {
                return <RenderHeaderComponent {...params} />
            },
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            color: "#68727B",
                            fontSize: 12,
                            fontWeight: 400
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            }
        },

        {
            field: "restant",
            headerName: "Restant",
            //width: 90,
            width: 50,
            flex: 1,
            renderHeader: (params) => {
                return <RenderHeaderComponent {...params} />
            },
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            color: "#68727B",
                            fontSize: 12,
                            fontWeight: 400
                        }}
                    >
                        {parseFloat(cellValues.value).toLocaleString()}

                    </div>
                );
            }
        },

        {
            field: "total",
            headerName: "Total",
            width: 90,
            sortable: false,
            flex: 1,
            renderHeader: (params) => {
                return <RenderHeaderComponent {...params} />
            },
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            color: "#68727B",
                            fontSize: 12,
                            fontWeight: 400
                        }}
                    >
                        {parseFloat(cellValues.value).toLocaleString()}

                    </div>
                );
            }
        },

        {
            field: "status",
            headerName: "Status",
            sortable: false,
            minWidth: 90,
            flex: 2,
            // valueGetter: (params) => {
            //   const restant = params.row.montant_restant;
            //   const statut = params.row.statut_paiement;
            //   if (restant === 0) {
            //     return restant === 0 ? "Payé" : statut;
            //   } else {
            //     return restant !== 0 ? "Partiel" : statut
            //   }

            // },

            renderHeader: (params) => {
                return <RenderHeaderComponent {...params} />
            },

            renderCell: (cellValues) => {
                return (
                    <div>
                        <p
                            style={{
                                padding: "10px 20px",


                                color:
                                    ((cellValues.value === 'Paiement partiel' && '#FCA13A')) ||
                                    ((cellValues.value === 'Payée' && ' #11706A')),

                                fontSize:
                                    ((cellValues.value === 'Paiement partiel' && '10px')) ||
                                    ((cellValues.value === 'EN ATTENTE' && ' 10px')),

                                backgroundColor:
                                    ((cellValues.value === 'Paiement partiel' && '#fff3e4')) ||
                                    ((cellValues.value === 'Payée' && ' #e7f1f0')),

                            }}
                        >  {cellValues.value}</p>
                    </div>
                );
            }

        },

        {
            field: "Action",
            headerName: "Action",
            sortable: false,
            disableColumnMenu: true,
            minWidth: 100,
            flex: 1,
            renderHeader: (params) => {
                return <RenderHeaderComponent {...params} />
            },

            renderCell: (params) => {
                if (params.row.restant === 0) {

                } else {
                    return <RenderCellComponent {...params} />
                }
            }

        },

        {
            field: "",
            headerName: "",
            sortable: false,
            disableColumnMenu: true,
            minWidth: 250,
            flex: 1,
            renderHeader: (params) => {
                return <RenderHeaderComponent {...params} />
            },

            renderCell: (params) => {
                return <RenderCellsComponent {...params} />
            }

        },
    ];

    const RenderCellsComponent = (params) => {
        const [ouvert, setOuvert] = useState(false);
        const handleOpen = () => {
            setOuvert(true);

        };
        const [openNew, setOpenNew] = useState(false)


        const handleOuvert = () => {
            setOpenNew(true);
        };
        const out = () => {
            setOpenNew(false)
        };
        const onclose = () => {
            // window.print()
            // setOpenNew(false);
            const printWindow = window.open('', '', '');
            printWindow.document.open();
            printWindow.document.write(`
                    <html>
                    <head>
                        <title>.</title>
                    </head>
                    <body>
                        
                        ${document.getElementById('print').innerHTML}
                    
                    </body>
                    </html>
                    `);
            printWindow.document.close();
            printWindow.print();
            printWindow.close();
            setOpenNew(false)
        };

        const close = () => {
            setOuvert(false);
        };

        const fermer = () => {
            setOuvert(false);
        };

        const service = params.row;
        const donnees = params.row.donnees;
        const [opened, setOpen] = React.useState(false);

        const theme = useTheme();

        const handleClickOpen = () => {
            setOpen(true);

        };



        const handleClosed = () => {
            setOpen(false);
        };



        const deleteDetente = async () => {
            const id = service.id;
            try {
                const resp = await api.deletedetente(id);
                Notiflix.Loading.standard("Suppression en cours...", { svgColor: "#FCA13A" });
                window.location.reload();

            } catch (error) {
                // console.log(error)
            }

        };

        return (
            <div style={{ display: "flex", gridGap: "30px" }}>
                <button
                    onClick={handleClickOpen}
                    style={{ marginLeft: "30px", background: "#b1e0e9", border: "none", padding: "5px 10px", color: "#fff" }}>détails</button>
                <Dialog
                    open={opened}
                    onClose={handleClosed}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{
                        sx: {
                            width: "100%",
                            maxWidth: "720px!important",
                            height: "130vh"
                        }
                    }}

                >
                    <DialogTitle id="alert-dialog-title" sx={{ fontSize: "20px", textAlign: "center" }}>
                        {"Détails de la réservation de SenPhama détente"}
                    </DialogTitle>
                    <div>
                        <table style={{ borderCollapse: "collapse", marginLeft: "50px", marginTop: "60px" }}>
                            <thead style={{ border: "1px solid #dddd", background: "#FCA13A", color: "#ffff" }}>
                                <tr>
                                    <th style={{ padding: "12px 50px" }}>Packs</th>
                                    <th style={{ padding: "12px 50px" }}>Quantité</th>
                                    <th style={{ padding: "12px 50px" }}>Prix</th>
                                    <th style={{ padding: "12px 50px" }}>Montant</th>
                                </tr>
                            </thead>

                            <tbody>

                                {donnees?.map((item, index) => (
                                    <tr key={index}>
                                        <td style={{ padding: "12px 15px", fontSize: "12px" }}>{item?.pack}</td>
                                        <td style={{ padding: "12px 50px" }}>{item?.quantite}</td>
                                        <td style={{ padding: "12px 50px" }}>{(item?.prix).toLocaleString()}</td>
                                        <td style={{ padding: "12px 50px" }}>{(item?.prix * item?.quantite).toLocaleString()}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </Dialog>
                <div style={{ marginTop: "8px", color: "#FCA13A" }} onClick={handleOpen}>
                    <DeleteIcon />
                </div>

                {(() => {
                    if (service.status === "Payée") {
                        return (
                            <div style={{ marginTop: "10px", color: "green" }} onClick={handleOuvert}>
                                <PrintIcon />
                            </div>
                        )

                    } else {

                    }
                })()}






                <Dialog
                    open={ouvert}
                    onClose={close}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{
                        sx: {
                            width: "100%",
                            maxWidth: "720px!important",
                            height: "50vh"
                        }
                    }}

                >
                    <p style={{ textAlign: "center", padding: "30px", fontSize: "35px" }}>
                        Voulez-vous vraiement supprimer cette réservation ?

                    </p>
                    <div style={{ display: "flex", gap: "20px", marginLeft: "270px" }}>
                        <button onClick={deleteDetente} style={{ padding: "15px 20px", backgroundColor: "#b1e0e9", border: "none", color: "#fff" }}>Oui</button>
                        <button onClick={fermer} style={{ padding: "15px 20px", background: "linear-gradient(83.26deg, #F5841F 17.33%, #FFC380 83.63%)", border: "none", color: "#fff" }}>Annuler</button>
                    </div>
                </Dialog>



                <Dialog
                    open={openNew}
                    onClose={out}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"

                    PaperProps={{
                        sx: {
                            width: "100%",
                            maxWidth: "720px!important",
                            height: "130vh",
                            maxHeight: "100vh"
                        }
                    }}


                >
                    <div id="print">
                        <div style={{ display: "flex", gridGap: "500px" }}>

                            <img src={image} alt="" style={{ width: "100px", height: "50px", marginLeft: "10px" }} />
                            <h3 style={{ marginLeft: "-30px" }}>FACTURE </h3>
                        </div>
                        <div style={{ marginLeft: "20px", marginTop: "30px", display: "flex", gridGap: "190px" }}>
                            <div style={{ fontSize: "12px" }}>
                                <h5>Client</h5>
                                <p>N0: <span style={{ fontSize: "15px", fontWeight: "700", marginLeft: "40px" }}>{service.id} </span></p>
                                <p>Nom: <span style={{ fontSize: "15px", fontWeight: "700", marginLeft: "40px" }}> {service.nom} </span></p>
                                <p>Email <span style={{ fontSize: "15px", fontWeight: "700", marginLeft: "40px" }}> {service.email} </span></p>
                                <p> Téléphone: <span style={{ fontSize: "15px", fontWeight: "700", marginLeft: "40px" }}> {service.telephone} </span> </p>

                            </div>
                            <div style={{ fontSize: "12px" }}>
                                <h5>SenPharma</h5>
                                <p>Adresse: <span style={{ fontSize: "15px", fontWeight: "700", marginLeft: "10px" }}>10, boulevard Dial Diop. </span></p>
                                <p tyle={{ fontSize: "15px", fontWeight: "700", marginLeft: "40px" }}>Amitié Grand Dakar, Dakar, Sénégal</p>
                                <p>Email <span style={{ fontSize: "15px", fontWeight: "700", marginLeft: "10px" }}> contact@senpharma.org </span></p>
                                <p> Téléphone: <span style={{ fontSize: "15px", fontWeight: "700", marginLeft: "10px" }}> +221 77 514 21 76</span> </p>
                                <p tyle={{ fontSize: "15px", fontWeight: "700", marginLeft: "40px" }}>   +221 77 669 21 63 </p>
                            </div>

                        </div>

                        <table style={{ borderCollapse: "collapse", marginTop: "40px", width: "95%", marginLeft: "17px" }}>
                            <thead style={{ border: "1px solid #dddd", background: "#FCA13A", color: "#ffff" }}>
                                <tr>
                                    <th style={{ padding: "10px 15px" }}>Packs</th>
                                    <th style={{ padding: "10px 15px" }}>Quantité</th>
                                    <th style={{ padding: "10px 15px" }}>Prix</th>
                                    <th style={{ padding: "10px 15px" }}>Montant</th>
                                </tr>
                            </thead>
                            <tbody>
                                {donnees?.map((item, index) => (
                                    <tr key={index}>
                                        <td style={{ padding: "12px 40px", fontSize: "13px" }}>{item?.pack}</td>
                                        <td style={{ padding: "12px 40px" }}>{item?.quantite}</td>
                                        <td style={{ padding: "12px 40px" }}>{(item?.prix).toLocaleString()}</td>
                                        <td style={{ padding: "12px 40px" }}>{(item?.prix * item?.quantite).toLocaleString()}</td>
                                    </tr>
                                ))}

                            </tbody>

                        </table>

                        {/* <div style={{ position: "relative", left: "530px", top: "10px", width: "25%" }}> */}
                        <h4 style={{ marginLeft: "420px" }}>Total TTC: <span style={{ fontSize: "15px", fontWeight: "700", marginLeft: "40px" }}>{(service.total).toLocaleString()} Fcfa</span></h4>
                        <img src={cachet} alt="" style={{ width: "300px", height: "300px", marginLeft: "200px", marginTop: "50px" }} />

                        {/* </div> */}


                    </div>
                    <button onClick={onclose} style={{ padding: "15px 30px", background: "#b1e0e9", border: "none", color: "#fff", marginLeft: "250px", marginTop: "30px", width: "25%" }} >imprimer</button>


                </Dialog>
            </div>
        )

    };


    const RenderCellComponent = (params) => {
        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);
        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
            setAnchorEl(null);
        };

        const [opened, setOpen] = React.useState(false);

        const theme = useTheme();

        const handleClickOpen = () => {
            setOpen(true);

        };



        const handleClosed = () => {
            setOpen(false);
        };




        const [data, setData] = useState()
        const [contact, setContact] = useState('')
        const [type_paiement, setType_paiement] = useState('')
        const [date, setDate] = useState('')
        const [montant, setMontant] = useState('')
        const [numero_transaction, setNumero_transaction] = useState('')
        const [detenteId, setDetenteId] = useState('');
        const service = params.row;
        const restant = params.row.restant;




        const handleSubmit = async (e) => {
            e.preventDefault();
            const inputData = {

                contact: contact || (service.nom),
                type_paiement: type_paiement,
                date: date,
                montant: montant,
                numero_transaction: numero_transaction,
                detenteId: service.id


            }
            // console.log(inputData)

            try {
                const resp = await api.createpaiementdetente(inputData);
                // console.log(resp.data)
                Notiflix.Loading.standard("Paiement en cours...", { svgColor: "#FCA13A" });
                window.location.reload();
                handleClosed();


            } catch (error) {
                console.log(error)
            }


        }








        return (
            <div
                style={{
                    fontSize: 12,
                    fontWeight: 400,
                    display: "flex",
                    gridGap: "20px",

                }}
            >


                <div >
                    <IconButton
                        onClick={handleClick}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        style={{ color: "#b1e0e9", fontWeight: "700" }}

                    >
                        <MoreHorizIcon />
                    </IconButton>


                </div >
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            width: "160px",
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',


                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >

                    <MenuItem onClick={handleClickOpen} style={{ fontSize: "12px", backgroundColor: "#fff3e4", borderRadius: "10px", color: "#666666", width: "130px", justifyContent: "center", marginLeft: "15px" }}>
                        nouveau paiement
                    </MenuItem>
                    <Dialog
                        open={opened}
                        onClose={handleClosed}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        PaperProps={{
                            sx: {
                                width: "100%",
                                maxWidth: "720px!important",
                                height: "80vh"
                            }
                        }}

                    >
                        <DialogTitle id="alert-dialog-title" sx={{ fontSize: "14px", textAlign: "center" }}>
                            {"PAIEMENT"}
                        </DialogTitle>
                        <div className="statut_montant" style={{ marginLeft: "20px", fontSize: "12px", display: "flex" }}>
                            <p>Montant restant{""}
                                <span style={{ marginLeft: "20px", backgroundColor: "#FBFBFB", padding: "20px 50px", fontSize: "40px" }}>{(service.restant).toLocaleString()}</span>
                            </p>

                        </div>
                        <form onSubmit={handleSubmit}>

                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    <Box sx={{ marginTop: "20px" }}>
                                        <div style={{ display: "flex", marginRight: "35px", marginBottom: "30px" }}>
                                            <p style={{ fontSize: "12px", width: "19%" }} >Date</p>
                                            <TextField

                                                onChange={(e) => {
                                                    setDate(e.target.value)

                                                }}
                                                value={date}
                                                type="date"
                                                name="date"
                                                required
                                                fullWidth
                                                autoFocus
                                                size="small"
                                                sx={{ backgroundColor: "#FBFBFB" }}
                                            />
                                        </div>
                                        <div style={{ display: "flex", gridGap: "20px" }}>
                                            <div style={{ display: "flex", gridGap: "10px" }}>
                                                <p style={{ fontSize: "12px", width: "39%" }}>Nom & Prenom</p>
                                                <TextField
                                                    value={contact || (service.nom)}
                                                    name="nom"
                                                    onChange={(e) => {
                                                        setContact(e.target.value)
                                                    }}
                                                    required
                                                    fullWidth
                                                    id="contact"
                                                    autoFocus
                                                    size="small"
                                                    sx={{ backgroundColor: "#FBFBFB" }}
                                                />
                                            </div>
                                            <div style={{ display: "flex", gridGap: "20px" }}>
                                                <p style={{ fontSize: "12px" }}>Type de payement</p>
                                                <FormControl fullWidth style={{ width: "180px" }}>
                                                    <Select
                                                        onChange={(e) => {
                                                            setType_paiement(e.target.value)
                                                        }}
                                                        name="type_paiement"
                                                        value={type_paiement}
                                                        inputProps={{ 'aria-label': 'Without label', }}
                                                        size="small"
                                                        sx={{ backgroundColor: "#FBFBFB" }}

                                                    >
                                                        <MenuItem value="wave">Wave
                                                        </MenuItem>
                                                        <MenuItem value="FreeMoney" >FreeMoney</MenuItem>
                                                        <MenuItem value="Orange money" >Orange money</MenuItem>
                                                        <MenuItem value="Chèque" >Chèque</MenuItem>
                                                        <MenuItem value="virement banquaire" >virement banquaire</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", gridGap: "20px", marginTop: "30px", marginLeft: "20px" }}>
                                            <div style={{ display: "flex", gridGap: "38px" }}>
                                                <p style={{ fontSize: "12px", width: "39px" }}>Numéro transaction</p>
                                                <TextField
                                                    onChange={(e) => {
                                                        setNumero_transaction(e.target.value)
                                                    }}
                                                    name="numero_transaction"
                                                    value={numero_transaction}
                                                    required
                                                    fullWidth
                                                    id="numero_transaction"
                                                    autoFocus
                                                    size="small"
                                                    sx={{ backgroundColor: "#FBFBFB", height: "40px" }}
                                                />
                                            </div>
                                            <div style={{ display: "flex", marginLeft: "30px" }}>
                                                <p style={{ fontSize: "12px", width: "50%" }} >Montant</p>
                                                <TextField
                                                    name="montant"
                                                    onChange={(e) => {
                                                        if ((e.target.value) <= restant) {
                                                            setMontant(e.target.value)

                                                        } else {
                                                            return "le montant doit inferieur à montant restant"
                                                        }

                                                    }}
                                                    value={montant}
                                                    required
                                                    fullWidth
                                                    id="firstName"
                                                    autoFocus
                                                    size="small"
                                                    sx={{ backgroundColor: "#FBFBFB", height: "40px" }}
                                                />
                                            </div>


                                        </div>


                                    </Box>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions  >
                                <button onClose={handleClosed} style={{ padding: "15px 30px", position: "relative", left: "-300px", top: "30px", background: "#FCA13A", border: "none", color: "#fff" }}>Valider</button>
                            </DialogActions>

                        </form>
                    </Dialog>

                </Menu>



            </div >

        );
    };



    const RenderHeaderComponent = (params) => {
        return (
            <Box display="flex" sx={{ alignItems: 'center' }}>
                <Box>{params.colDef.headerName}</Box>
                <ArrowDropDownIcon color="inherit" />
            </Box>
        )
    };

    const getdetente = async () => {
        try {
            const reponse = await api.getdetente()
            // console.log(reponse.data)
            setTableData(reponse.data)
        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        getdetente();
    }, []);



    return (
        <div style={{ height: 1000, width: "100%" }} className="no-print">
            <DataGrid
                //toolBar={<Toolbar sx={{ backgroundColor: "blue" }} />}

                sx={datagridSx}
                rows={tableData}
                columns={columns}
                pageSize={4}
                checkboxSelection
                // onCellClick={handleCellClick}
                onRowClick={handleRowClick}

            />

        </div>
    );
}
