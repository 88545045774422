import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Header from "../../Components/header/Header";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import "./style.css";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 8,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#FCA13A",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#FCA13A",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#E6EDFE",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "none",
  zIndex: 1,
  width: 15,
  border: "1px solid #E6EDFE",
  height: 15,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    border: "1px solid #FCA13A",
  }),
  ...(ownerState.completed && {
    border: "1px solid #FCA13A",
    backgroundColor: "#FCA13A",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;
  const icons = {
    1: "",
    2: "",
    3: "",
    4: "",
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const steps = ["Choix", "Formulaire", "Reservation", "Merci"];

const FinReservation = () => {

  const [isSmallScreen, setIsSmallScreen] = useState(false);


  useEffect(() => {
    function handleResize() {
      // Mettre à jour isSmallScreen en fonction de la largeur de l'écran
      setIsSmallScreen(window.innerWidth <= 768);
    }

    // Écouter les changements de taille d'écran
    window.addEventListener('resize', handleResize);

    // Appel initial de la fonction de gestion de la taille de l'écran
    handleResize();

    // Nettoyage de l'écouteur d'événements lors du démontage du composant
    return () => {
      window.removeEventListener('resize', handleResize);
    }


  }, []);

  const [isFalse, setIsFalse] = useState(true);

  const handleToggle = () => {
    setIsFalse(!isFalse);
    // console.log(isFalse);
  };

  const responseData = localStorage.getItem("response");

  return (
    <div className="containe">
      <Header handleMenu={handleToggle} />

      <div className="main-card4">
        {isFalse && (
          <>
            <div className="card4">
              <p>Merci</p>
              <h3>{responseData}</h3>
              <span>Senpharma.sn</span>
            </div>

          </>
        )}
      </div>

      {isSmallScreen ? (
        <> <div >
          {isFalse && (
            <>
              <Stepper
                alternativeLabel
                activeStep={4}
                connector={<ColorlibConnector />}
                sx={{
                  width: "290px",
                  marginLeft: "30px",
                  marginTop: "50px",
                  backgroundColor: "#fff",
                  color: "#FCA13A",
                  borderRadius: "10px",
                  padding: "8px 10px",
                  "& .css-1hv8oq8-MuiStepLabel-label.Mui-completed": {
                    color: "#FCA13A",
                  },
                  "& .Mui-active": {
                    color: "#68727B",
                  },
                  "& .Mui-disabled": {
                    color: "#68727B",
                  },
                }}
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </>
          )}

          <br />
          <br />
          <br />

        </div>
        </>

      ) : (
        <>
          <div className="steppe" >
            {isFalse && (
              <>
                <Stepper
                  alternativeLabel
                  activeStep={4}
                  connector={<ColorlibConnector />}
                  sx={{
                    width: "300px",
                    marginLeft: "490px",
                    backgroundColor: "#fff",
                    color: "#FCA13A",
                    borderRadius: "10px",
                    padding: "8px 10px",
                    "& .css-1hv8oq8-MuiStepLabel-label.Mui-completed": {
                      color: "#FCA13A",
                    },
                    "& .Mui-active": {
                      color: "#68727B",
                    },
                    "& .Mui-disabled": {
                      color: "#68727B",
                    },
                  }}
                >
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel StepIconComponent={ColorlibStepIcon}>
                        {label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </>
            )}
            <br />
            <br />
            <br />


          </div>
        </>
      )}

    </div>
  );
};

export default FinReservation;
