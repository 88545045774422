import React, { useEffect, useState } from 'react';
import Header from '../../Components/header/Header'
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import image from '../../images/hotel.webp';
import { Table } from '@mui/material';
import api from '../../service/api';
import './style.css';


const SenPharmaDetente = () => {
    const [data, setData] = useState([]);

    const getDesc = async () => {
        try {

            const response = await api.getDesc();
            // setData(data.data);
            const resp = Object.values(response.data).filter(user => user.type_description === "detente");
            setData(resp);
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getDesc();
    }, []);

    const [isFalse, setIsFalse] = useState(true);
    const handleToggle = () => {
        setIsFalse(!isFalse);
        // console.log(isFalse);
    };

    return (
        <div className="containe">
            <Header handleMenu={handleToggle} />
            <div className='detente' >
                <Grid item xs={12} md={6} >
                    <Card sx={{ display: 'flex' }} className='Card'>
                        <CardMedia
                            className='cardmedia'
                            component="img"
                            sx={{ width: 500, display: { xs: 'none', sm: 'block' } }}
                            image={image}
                            alt=''
                        />
                        <CardContent sx={{ flex: 1 }} className='cardcontend' >
                            <Typography component="h2" variant="h5" sx={{
                                fontSize: "30px", fontWeight: "700",
                                '@media (max-width: 768px)': {
                                    marginLeft: "-40px",
                                },
                            }}>
                                SenPharma Détente <br />
                                <span style={{ fontSize: "14px", fontWeight: "700" }}>
                                    (Baobab ex Décaméron)
                                </span>
                            </Typography>

                            <Typography variant="subtitle1" paragraph sx={{ textAlign: "justify", marginTop: "30px", fontSize: "14px" }}>
                                {data.map((item, i) =>
                                    <p key={i} style={{ fontWeight: "700" }}>
                                        le SenPharma vous donne rendez-vous les {item?.description} pour cloturer en beauté
                                        cette 10ème édition. Le programe se procède coomme suit: <br />
                                    </p>
                                )}
                                <div className='list'>
                                    <p>&#x2192; <span style={{ marginLeft: "20px" }}>Football</span>  <br />
                                        &#x2192; <span style={{ marginLeft: "20px" }}>Natation</span>  <br />
                                        &#x2192; <span style={{ marginLeft: "20px" }}>Jeux de societé ...</span>  <br />
                                        &#x2192; <span style={{ marginLeft: "20px" }}>Espace de beauté (spa) </span> <br />
                                        &#x2192; <span style={{ marginLeft: "20px" }}>Diner de gala le samedi 22 juillet avec orchestre</span> <br />
                                        &#x2192;<span style={{ marginLeft: "20px" }}>Détails des Packs</span> </p>
                                </div>

                                <div>
                                    <Table sx={{
                                        display: "flex", gridGap: "100px", alignItems: "center", justifyContent: "center", fontSize: "14px", fontWeight: "700",
                                        '@media (max-width: 768px)': {
                                            display: "flex", gridGap: "30px", marginLeft: "-10px"
                                        },
                                    }} >
                                        <div className='TableDetente'>
                                            <h4>Tarifs adultes</h4>
                                            <tr>
                                                <td>chb doube: 75.000/nuit </td>
                                            </tr>
                                            <tr>
                                                <td>chb Single:114 000/nuit</td>
                                            </tr>
                                            <tr>
                                                <td>Diner de gala: 50.000 </td>
                                            </tr>
                                        </div>

                                        <div className='TableDetente'>
                                            <h4>Tarifs enfants</h4>
                                            <tr>
                                                <td>0 à 3ans: gratuit</td>
                                            </tr>
                                            <tr>
                                                <td>4 à 11ans: 37.500/nuit </td>
                                            </tr>
                                            <tr>
                                                <td>+12ans: Tarifs adultes</td>
                                            </tr>
                                        </div>

                                    </Table>
                                </div>
                                <div style={{ marginTop: "10px", fontSize: "14px", fontWeight: "700", }} className='tittle'>
                                    <p>  &#x2192; Optionnel: Transport Baobab aller retour CICAD pour 3 jours: 30.000 </p>

                                </div>

                                <div className='tab'>
                                    <table border={1} style={{ borderCollapse: "collapse", marginLeft: "50px", marginTop: "20px" }}>
                                        <thead >
                                            <tr>
                                                <th style={{ padding: "12px 15px" }}>Packs</th>
                                                <th style={{ padding: "12px 15px" }}>Adulte double</th>
                                                <th style={{ padding: "12px 15px" }}>Adulte Single</th>
                                                <th style={{ padding: "12px 15px" }}>Enfants</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr style={{ fontWeight: "700" }}>
                                                <td style={{ padding: "12px 15px" }}>2 Nuitées</td>
                                                <td style={{ padding: "12px 15px" }}>200.000</td>
                                                <td style={{ padding: "12px 15px" }}>278.000</td>
                                                <td style={{ padding: "12px 15px" }}>75.000</td>

                                            </tr>
                                            <tr style={{ fontWeight: "700" }}>
                                                <td style={{ padding: "12px 15px" }}>5 Nuitées</td>
                                                <td style={{ padding: "12px 15px" }}>425.000</td>
                                                <td style={{ padding: "12px 15px" }}>640.000</td>
                                                <td style={{ padding: "12px 15px" }}>187.000</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </Typography>
                            <Typography component='a' href='/partenaire/detente' className='a' sx={{
                                padding: "15px 20px", background: "#FCA13A", border: "none", color: "#fff", width: "30%", paddingLeft: "30px", textDecoration: "none",
                            }}>
                                Réserver packs
                            </Typography>
                        </CardContent>

                    </Card>
                </Grid >

                <br />
                <br />
            </div >
        </div >
    );
};

export default SenPharmaDetente;