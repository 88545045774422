import React from "react";

const ProgressBar = (props) => {
    const { bgcolor, completed, tittle } = props;

    const containerStyles = {
        height: 10,
        width: "40%",
        backgroundColor: "#e0e0de",
        borderRadius: 50,
        marginTop: 30,
        marginLeft: "140px",
        justifyContent: "center",
        // textAlign: "justify"
    };

    const fillerStyles = {
        height: "100%",
        width: `${completed}% `,
        backgroundColor: bgcolor,
        borderRadius: "inherit",
        marginTop: "-16px",
        // textAlign: 'right'
    };

    const labelStyles = {
        // padding: 5,
        marginLeft: "180px",
        color: "red",
        marginRight: "40px",
        // marginTop: "-50px",
        fontWeight: "bold",
        position: "relative",
        top: "-5px"

    };
    const test = {
        display: "flex",
        // alignItem: "center",
        height: "30px",
    };
    const titre = {
        // marginTop: "50px",
        textAlign: "center",
        right: "150px",
        bottom: "5px",
        position: "relative",
        fontSize: "12px",
    };

    return (
        <div style={test}>
            <div style={containerStyles}>
                <div style={titre}>{`${tittle}`}</div>
                <div style={fillerStyles}>

                    <span style={labelStyles}>{`${completed}`}</span>
                </div>
            </div>
        </div>
    );
};

export default ProgressBar;
